import { Master } from './master.class';
import { Observable } from 'rxjs';
import { ActionNotice } from '../interfaces/action.notice';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AvenueInjector } from './avenue.injector';
import { takeUntil } from 'rxjs/operators';
import { select } from '@ngrx/store';

/**
 * @description
 * Declare interface that will force you to implement two basic function for the component action.
 * @function initForm(): void
 * @function initActionSub(): void
 * @example initForm(){
 *      this.forms['formName'] = this.fb.group({
 *          conrtol1: [value1, validators]
 *      })
 * }
 * @example initActionSub(){
 *      this.ActionLoading$ = this.store.pipe(
 *          select(actionLoaderSelectorName),
 *          takeUntil(whenToUnsubscribe)
 *      )
 * }
 */

export declare interface HasForms {
    /**
     * The function that will init the form.
     * The form will be FormGroup [alias]. 
     * FormBuilder is injected in fb protected property.
     */
    initForm(): void;
    // initActionSub(): void;
};

export class ActionModel extends Master {

    public ActionLoading$: Observable<boolean>;
    public ActionSubmited$: Observable<boolean>;
    public ActionNotice$: Observable<ActionNotice>;

    public forms: FormGroup[] = [];

    protected fb: FormBuilder;

    constructor(){
        super();
        const avenue = AvenueInjector.getInjector();
        this.fb = avenue.get(FormBuilder);
    }

    protected initActionSub(___ActionLoading, ___ActionSubmited, ___ActionNotice = null){
        this.ActionLoading$ = this.store.pipe(
            takeUntil(this._onDestroy),
            select(___ActionLoading)
        );
        this.ActionSubmited$ = this.store.pipe(
            takeUntil(this._onDestroy),
            select(___ActionSubmited)
        );
        if(___ActionNotice)
            this.ActionNotice$ = this.store.pipe(
                takeUntil(this._onDestroy),
                select(___ActionNotice)
            );
    }

    /**
     * Check inputs validations
     * @param conrtolName Control name in the form
     * @param formName Form group name
     */
    public inputHasError(conrtolName: string, formName: string): boolean {
        return !this.forms[formName].controls[conrtolName].touched ? true : this.forms[formName].controls[conrtolName].valid;
    }

}