import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { QueryClass } from 'src/app/core/crud/query';
import { Block } from 'src/app/core/interfaces/block';

@Injectable({
    providedIn: 'root'
})
export class BlockService {

    constructor(
        private http: HttpClient
    ) { }

    buyBlockForm(block: Block, formNumber: number, open: boolean = false): Observable<any>
    {
        return this.http.put(environment.backend+'blocks/block/buy/form', {block: block, form: formNumber, open: open});
    }

    buyMultiBlockForm(block: Block, formNumbers: number[]): Observable<any>
    {
        return this.http.put(environment.backend+'blocks/block/buy/multiForm', {block: block, forms: formNumbers});
    }

    loadBlockByIdWithNumbers(query: QueryClass, id: number): Observable<any>
    {
        return this.http.get(environment.backend+'games/block/load/'+id+query.getQueryAsUrl());
    }
}